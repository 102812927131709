<template>
  <div class="accordion" role="tablist">
    <b-card v-for="(item, index) in data" :key="item.id" no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          block
          @click="toggle(index)"
          variant="info"
          style="
            background-color: #17a2b8;
            text-align: left;
            border-color: transparent;
          "
          >{{ item.nama_materi
          }}<i
            class="flaticon2-right-arrow float-right rotate-icon"
            :class="{ rotate: activeIndexes.includes(index) }"
          ></i
        ></b-button>
      </b-card-header>
      <b-collapse :visible="activeIndexes.includes(index)" role="tabpanel">
        <b-card-body>
          <b-card-text>{{ text }}</b-card-text>
          <b-button
            size="l"
            class="btn-spacing"
            style="background-color: transparent; border-color: gray"
            href="https://be.penilaiankinerja.mysurvey.id/public/upload/1709047001_ckeditor4-export-pdf.pdf"
          >
            Download
            <i class="fas fa-solid fa-file-pdf ml-2" style="color: red"></i>
          </b-button>
          <b-button
            size="l"
            style="background-color: transparent; border-color: gray"
            v-b-modal.video-modal
          >
            Video <i class="fas fa-solid fa-video ml-2" style="color: red"></i>
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-modal id="video-modal" size="xl" hide-footer>
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/dQw4w9WgXcQ"
        allow="autoplay"
        allowfullscreen
      ></b-embed>
    </b-modal>
    <!-- kayaknya gaperlu pake modal buat pdf viewer deh gw bingung soalnya -->
  </div>
</template>

<script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Materi",
  data() {
    return {
      data: [],
      token: localStorage.getItem("id_token"),
      url: localStorage.getItem("baseapi"),
      text: `
      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
      richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
      brunch. Food truck quinoa nesciunt laborum eiusmod.
      `,
      activeIndexes: [],
    };
  },
  methods: {
    toggle(index) {
      const position = this.activeIndexes.indexOf(index);
      if (position !== -1) {
        this.activeIndexes.splice(position, 1);
      } else {
        this.activeIndexes.push(index);
      }
    },
    profilPemateri() {
      axios
        .get(this.url + "/profil_pemateri", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.data = response.data.data;
          console.log(data);
        })
        .catch((errpr) => {
          console.error(error);
        });
    },
    fileMateri() {
      axios
        .get(this.url + "/file_materi", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // downloadFile(fileUrl) {
    //   // Mendapatkan nama file dari URL
    //   let fileName = fileUrl.split("/").pop();

    //   // Buat request untuk mendapatkan file tersebut
    //   axios({
    //     url: fileUrl,
    //     method: "GET",
    //     responseType: "blob", // penting untuk di-set agar response bisa dijadikan blob
    //   }).then((response) => {
    //     // Buat blob dari response data
    //     const blob = new Blob([response.data], { type: response.data.type });

    //     // Gunakan file-saver untuk menyimpan file dengan nama asli
    //     saveAs(blob, fileName);
    //   });
    // },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Materi" }]);
    this.fileMateri();
  },
};
</script>

<style scoped>
.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
}

.btn-spacing {
  margin-right: 10px;
}
</style>
